import * as React from "react";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import { Typography, Box, Container } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const Head = () => (
  <HtmlHead title="People | Centrum dopravního výzkumu, v. v. i.">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="People" lang="cs" />
    <meta name="keywords" content="people" lang="cs" />
    <meta name="robots" content="index, follow" />
    <meta name="rating" content="general" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </HtmlHead>
);

export default function Page() {
  const aCss = {
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
  };

  return (
    <Layout>
      <Container sx={aCss}>

        <ul>
          <li>
            RNDr. Richard Andrášik, Ph.D.
            <br />
            <em>Mathematics, statistics, modelling</em>
          </li>
          <li>
            <Link to="mailto:michal.bil@cdv.cz">Prof. RNDr. Michal Bíl, Ph.D.</Link>
            <br />
            <em>
              Professor of Environmental Geography (GIS modelling, transportation networks, traffic
              crashes and incidents, sustainable transportation)
            </em>
          </li>
          <li>
            Mgr. Martina Bílová
            <br />
            <em>Traffic safety of vulnerable road users, head of Dept.</em>
          </li>
          <li>
            Mgr. Vojtěch Cícha
            <br />
            <em>image recognition, lidar data, analyses of movement data, GIS</em>
          </li>
          <li>
            Mgr. and Bc. Jan Kubeček
            <br />
            <em>Databases, programming</em>
          </li>
          <li>
            Mgr. Vojtěch Nezval, Ph.D.
            <br />
            <em>Transportation Geography, GIS</em>
          </li>
          <li>
            Mgr. Jiří Sedoník
            <br />
            <em>GIS, programming, cartography</em>
          </li>
          <li>
            Mgr. Zbyněk Janoška, Ph.D
            <br />
            <em>Traffic safety of vulnerable road users</em>
          </li>
        </ul>

        <Box>
          <StaticImage src="../images/team-edges-960.png" alt=""></StaticImage>
        </Box>
      </Container>
    </Layout>
  );
}
